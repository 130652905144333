import React from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { addDays } from "date-fns";
import {ErrorMessage} from 'formik'


const DatePickerField = ({ name, value, onChange, setField, handleChange }) => {
  return (
    <>
      <label htmlFor={name}>Coverage start date</label>
      <DatePicker
        name={name}
        selected={value}
        dateFormat="MM/dd/yyyy"
        minDate={addDays(new Date(), 1)}
        maxDate={addDays(new Date(), 60)}
        onChange={val => {
          handleChange(val)
          // setField(val);
          onChange(name, val);
        }}
      />
      <ErrorMessage name={name} component="div" className="field-error" data-testid="effectiveDate-error" />
    </>
  );
};

export default DatePickerField;