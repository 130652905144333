import React from 'react'
import { FieldArray } from 'formik'
import DateOfBirth from '../components/dateOfBirth'
import Sex from '../components/sex'
import TobaccoUse from '../components/tobaccoUse'
import add_img from '../../../images/add.png'

const Spouse = (props) => {
  return (
    <FieldArray
      name="spouse"
      render={({ insert, remove, push }) => (
        <div>
          {props.values.spouse.length > 0 &&
            props.values.spouse.map((child, index) => (
              <div className="row" key={index}>
                <div className="col field-heading">
                  <h4>Spouse</h4>
                  <button
                    type="button"
                    className="remove"
                    onClick={() => remove(index)}
                  >
                    Remove Spouse
                  </button>
                </div>
                <div className="flex-row">
                  <div className="flex-small">
                    <Sex {...props} name={`spouse.${index}.spouseSex`} fieldIndex={index} fieldName='spouse' fieldSubName='spouseSex'  labelText="Sex"/>
                  </div>
                  <div className="flex-small">
                    <TobaccoUse {...props} name={`spouse.${index}.spouseTobaccoUse`} fieldIndex={index} fieldName='spouse' fieldSubName='spouseTobaccoUse' labelText="Tobacco use? (past 12 months)" />
                  </div>
                  <div className="flex-small">
                    <DateOfBirth {...props} name={`spouse.${index}.spouseDateOfBirth`} fieldIndex={index} fieldName='spouse' fieldSubName='spouseDateOfBirth' minAge="18" maxAge="64" errorMessage="Spouse must be ages 18 to 64 on coverage start date"/>                  
                  </div>
                </div>
                
              </div>
            ))}
          {
          props.values.spouse.length < 1 ? 
            
            <button
              type="button"
              className="add-button"
              onClick={() => push({ spouseSex: "", spouseDateOfBirth: "", spouseTobaccoUse: "" })}
            >
              <img src={add_img} alt="Add spouse" />Add spouse <span> - optional </span>
            </button> : 
            
            null
          }
        </div>
      )}
    />
  )
}

export default Spouse
