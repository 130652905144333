import React from 'react'
import { Field, ErrorMessage } from 'formik'
import MaskedInput from 'react-text-mask'
import createAutoCorrectedDatePipe from 'text-mask-addons/dist/createAutoCorrectedDatePipe'
import moment from 'moment'

const dob = [/\d/,/\d/,"/",/\d/,/\d/,"/",/\d/,/\d/,/\d/,/\d/]
const autoCorrectedDatePipe = createAutoCorrectedDatePipe('mm/dd/yyyy', {"minYear": "1920"});

// DATE OF BIRTH VALIDATION
const validate = (val, maxAge, minAge, errorMessage, values) => {
  // const maxDate = moment(values.effectiveDate).subtract(maxAge, 'years').format('MM/DD/YYYY')
  // const minDate = moment(values.effectiveDate).subtract(minAge, 'years').format('MM/DD/YYYY')
  const maxDate = moment().subtract(maxAge, 'years').format('MM/DD/YYYY')
  const minDate = moment().subtract(minAge, 'years').format('MM/DD/YYYY')
  if(!val){
    return "Required"
  } else if( val.replace(/[^0-9]/g, "").length < 8 ) {
    return "Date must be at 8 characters long"
  } else if(moment(val, 'MM/DD/YYYY').isBefore(maxDate, 'MM/DD/YYYY')){
    return `${errorMessage}`
  } else if(moment(val, 'MM/DD/YYYY').isAfter(minDate, 'MM/DD/YYYY')){
    return `${errorMessage}`
  }
}

const DateOfBirth = ({hideLabel, labelText, name, className, errorClass, placeholder, noErrorMessage, errors, touched, handleChange, setField, errorMessage, fieldName, fieldSubName, fieldIndex, maxAge, minAge, values}) => (
  <div className="ttg-field">
    {
    hideLabel ? 
    null : 
    <label htmlFor="dateOfBirth">
      { labelText || "Date of Birth" }
    </label>  
    }
    <Field
      name={name ||"dateOfBirth" }
      validate={ (val) => validate(val, maxAge, minAge, errorMessage, values) }
      render={({ field }) => (
        <MaskedInput
          {...field}
          mask={dob}
          placeholder={placeholder || "MM/DD/YYYY"}
          type="text"
          guide={false}
          pipe={autoCorrectedDatePipe}
          className={ `${className || 'field'} ${
            errors[fieldName] && 
            errors[fieldName][fieldIndex] && 
            errors[fieldName][fieldIndex][fieldSubName] && 
            touched[fieldName] && 
            touched[fieldName][fieldIndex] && 
            touched[fieldName][fieldIndex][fieldSubName]  ? errorClass || "has-error" : ""
          }`}
          onChange={ (e) => {
            handleChange(e);
            setField(e);
          }}
        />
      )}
    />
    {
      noErrorMessage ?
      null :
      <ErrorMessage name={name || "dateOfBirth"} component="div" className="field-error" data-testid="dateOfBirth-error"/>
    }
  </div>
)

export default DateOfBirth;