import React, { Component } from 'react'
import { Formik, Form } from 'formik'
// import objectToFormData from 'object-to-formdata'
import {validateEffectiveDate} from './components/validate'
import axios from 'axios';
// import {navigate} from 'gatsby'
import { connect } from 'react-redux'
import { setAffParams, setIpAddress, setUserAgent, setUniversalLeadId } from '../../state/action'
import PageOne from './pages/pageOne'
import moment from 'moment-timezone'

var tomorrow = new Date();
tomorrow.setDate(tomorrow.getDate() + 1);


const pages = (props) => {

  return {
    1: <PageOne {...props} />
  }

}

class FormIndex extends Component {

  state = {
    sourceId: "LOCALHOST",
    CreatedByAgent: true,
    agentId: "3590333",
    effectiveDate: tomorrow,
    tabCode: 'STM',
    zip: "",
    sex: "",
    dateOfBirth: "",
    tobaccoUse: "",
    spouse: [],
    children: [],
    tier: "26"
  }

  componentDidMount(){
    // let dateNew = moment('12/06/2010', 'MM-DD-YYYY')
    // console.log(moment(dateNew).format('YYYY-MM-DD'))
    // const testDate =  moment().subtract(26, 'years').format('MM/DD/YYYY')
    // const enteredDate = "11/11/1992"
    // if(moment(enteredDate, 'MM/DD/YYYY').isAfter(testDate, 'MM/DD/YYYY')){
    //   console.log('greska')
    // } else {
    //   console.log('nije')
    // }
    const { zip } = this.props.formState

    this.setState({
      zip: zip || ''
    })
  }

  postToIHC = (values) => {

    values.tobaccoUse = values.tobaccoUse === "1" ? true : false
    values.effectiveDate = moment.tz(values.effectiveDate,  "America/New_York").format('YYYY-MM-DD')
    values.dateOfBirth = moment(values.dateOfBirth, 'MM/DD/YYYY').format('YYYY-MM-DD')
    values.children = values.children.map( child =>  {
      return {
        ...child,
        childTobaccoUse: child.childTobaccoUse === "1" ? true : false,
        childDateOfBirth: moment(child.childDateOfBirth, 'MM/DD/YYYY').format('YYYY-MM-DD')
      }

     } )
    if(values.spouse.length > 0) {
      values.spouseSex = values['spouse'][0]['spouseSex']
      values.spouseDateOfBirth = moment(values['spouse'][0]['spouseDateOfBirth'], 'MM/DD/YYYY').format('YYYY-MM-DD')
      values.spouseTobaccoUse = values['spouse'][0]['spouseTobaccoUse'] === "1" ? true : false
      delete values.spouse
    } else {
      delete values.spouse
    }
    console.log(values);

    axios.put(process.env.GATSBY_API, values,
    {
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    })
    .then(res => {console.log(`Results ${res.data.redirectUrl}`, window.location.replace( `${res.data.redirectUrl}` ))})
    .catch(error => console.log(error))
  }

  handleSubmit = (values,bag) => {
    const currentPage = this.props.page.pageIndex
    const LastPage = Object.keys(pages()).length;

    // let trusted_form_url_value =  document.getElementById('trusted_form_url_0').value;
    // values.trusted_form_url = trusted_form_url_value;
    if (currentPage === LastPage) {
      // alert(JSON.stringify(values, null, 2))
      // this.postToOnePingTree(values)
      bag.setSubmitting(true);
      this.postToIHC(values)
    } else {
      bag.setTouched({});
      bag.setSubmitting(false);
    }

  }

  setPage = (page, props) => {
    return (
      <div>
        {pages(props)[page]}
      </div>
    )
  }

  render(){
    return(
      <Formik
        validationSchema={validateEffectiveDate}
        initialValues={
          this.state
        }
        onSubmit={this.handleSubmit}
        enableReinitialize={true}
        render={(props) => (
          <Form>
            {/* {this.setPage(this.props.page.pageIndex, props={...props})} */}
            <PageOne {...props} />
          </Form >
        )}
      />
    )
  }
}

const mapStateToProps = ({ formState, page, meta }) => {
  return {
    formState,
    page,
    meta
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setAffParams: params => dispatch(setAffParams(params)),
    setIpAddress: ip_address => dispatch(setIpAddress(ip_address)),
    setUniversalLeadId: universal_leadid => dispatch(setUniversalLeadId(universal_leadid)),
    setUserAgent: user_agent => dispatch(setUserAgent(user_agent)),
  }
}

export default connect( mapStateToProps, mapDispatchToProps )( FormIndex )
