import React from "react"
import { Field, ErrorMessage } from "formik"


const validate = values => {
  if (!values) {
    return "Required"
  }
}

const Gender = ({hideLabel, labelText, name, className, errorClass, placeholder, noErrorMessage, errors, touched, handleChange, setField, errorMessage,fieldName, fieldSubName, fieldIndex, }) => (
  <div className="ttg-field">
    {
      hideLabel ? 
      null : 
      <label htmlFor="gender" name="gender">
        { labelText || "Gender" }
      </label>  
    }
    <Field
      className={ `${className || 'field'} ${
        errors[fieldName] && 
        errors[fieldName][fieldIndex] && 
        errors[fieldName][fieldIndex][fieldSubName] && 
        touched[fieldName] && 
        touched[fieldName][fieldIndex] && 
        touched[fieldName][fieldIndex][fieldSubName]  ? errorClass || "has-error" : ""
      }`}
      placeholder={ placeholder || "Gender"}
      component="select"
      name={name || "gender"}
      validate={validate}
      onChange={e => {
        handleChange(e)
        setField(e)
      }}
    >
      <option data-testid="gender-none" value="">Select</option>
      <option data-testid="gender-val-M" value="M">Male</option>
      <option data-testid="gender-val-F" value="F">Female</option>
    </Field>
    {
      noErrorMessage ?
      null :
      <ErrorMessage name={name || "gender"} component="div" className="field-error" data-testid="gender-error" />
    }
  </div>
)

export default Gender
