import React from "react"
import Layout from "../components/layout"
import Form from "../components/form"
import SEO from "../components/seo"

const FormPage = ({location}) => (

  <Layout location={location}>
    <SEO title="TTG-BOILERPLATE" />
    <div className="small-container">
      <div className="flex-row">
        <div className="flex-small form-column">
          <Form location={location} />
        </div>
      </div>
    </div>
  </Layout>

)

export default FormPage
