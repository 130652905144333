import React from 'react'
import { connect } from 'react-redux'
import Zip from '../components/zip'
import DateOfBirth from '../components/dateOfBirthApplicant'
import Gender from '@torchlight-technology/gender-field'
import TobaccoUse from '@torchlight-technology/smoker-field'
import Buttons from '../components/customButton'
import Child from '../components/child'
import Spouse from '../components/spouse'
import DatePicker from '../components/datePickerField'
import { setField, setHomeCityState, clearHomeCityState } from '../../../state/action'

const PageOne = props => (
  <>
    <h2>Applicant</h2>
    <div className="flex-row">
      <div className="flex-small">
        <Zip {...props} name="zip" />
      </div>
      <div className="flex-small">
        <DatePicker
          name="effectiveDate"
          value={props.values.effectiveDate}
          onChange={props.setFieldValue}
          {...props}
        />
      </div>
      <div className="flex-small">
        <Gender {...props} name="sex" labelText="Sex"/>
      </div>
    </div>

    <div className="flex-row">
      <div className="flex-small">
        <DateOfBirth {...props} maxAge="64" minAge="2"/>
      </div>
      
      <div className="flex-small">
        <TobaccoUse {...props} name="tobaccoUse" labelText="Tobacco use? (past 12 months)"/>
      </div>
    </div>
    
    
    <Spouse {...props} />
    <Child {...props} />
    <Buttons {...props} className="custom-btn primary-button" type="submit" />
  </>
)

const mapStateToProps = ({formState}) => {
  return { 
    formState
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setField: field => dispatch( setField( field ) ),
    setHomeCityState: data => dispatch( setHomeCityState(data)),
    clearHomeCityState: () => dispatch( clearHomeCityState() )
  }
}

export default connect( mapStateToProps, mapDispatchToProps )( PageOne )