// const validate = (values) => {
//   let errors = {};

//   if (!values.effectiveDate) {
//     errors.effectiveDate = 'Required';
//   }

//   return errors;
// };

// export default validate
import * as Yup from 'yup';
export const validateEffectiveDate = Yup.object().shape({
  effectiveDate: Yup.string()
    .required('Required')
    .nullable()
});