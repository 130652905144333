import React from 'react'
import { FieldArray } from 'formik'
import DateOfBirth from '../components/dateOfBirth'
import Sex from '../components/sex'
import TobaccoUse from '../components/tobaccoUse'
import add_img from '../../../images/add.png'

const Child = (props) => {
  return (
    <FieldArray
      name="children"
      render={({ insert, remove, push }) => (
        <div>
          {props.values.children.length > 0 &&
            props.values.children.map((child, index) => (
              <div className="row" key={index}>
                <div className="col field-heading">
                  <h4>Child</h4>
                  <button
                    type="button"
                    className="remove"
                    onClick={() => remove(index)}
                  >
                    Remove Child
                  </button>
                </div>
                <div className="flex-row">
                  <div className="flex-small">
                    <Sex {...props} name={`children.${index}.childSex`} fieldIndex={index} fieldName='children' fieldSubName='childSex' labelText="Sex" />
                  </div>
                  <div className="flex-small">
                    <DateOfBirth {...props} name={`children.${index}.childDateOfBirth`} fieldIndex={index} fieldName='children' fieldSubName='childDateOfBirth' maxAge="26" minAge="1" errorMessage="Dependent children must be under the age of 26"/>
                  </div>
                  <div className="flex-small">
                    <TobaccoUse {...props} name={`children.${index}.childTobaccoUse`} fieldIndex={index} fieldName='children' fieldSubName='childTobaccoUse' labelText="Tobacco use? (past 12 months)" />
                  </div>
                </div>
              </div>
            ))}
          <button
              type="button"
              className="add-button"
              onClick={() => push({ childSex: "", childDateOfBirth: "", childTobaccoUse: "" })}
            >
              <img src={add_img} alt="add child"/>Add child <span> - optional </span>
            </button>
        </div>
      )}
    />
  )
}

export default Child
