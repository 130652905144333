import React from "react"
import { Field, ErrorMessage } from "formik"


const validate = values => {
  if (!values) {
    return "Required"
  }
}

const tobaccoUse = ({hideLabel, labelText, name, className, errorClass, placeholder, noErrorMessage, errors, touched, handleChange, setField, errorMessage, fieldIndex, fieldName, fieldSubName}) => (
  <div className="ttg-field">
    {
      hideLabel ? 
      null : 
      <label htmlFor="smoker" name="smoker">
        Tobacco user?<span> (past 12 months)</span>
      </label>  
    }
    <Field
      className={ `${className || 'field'} ${
        errors[fieldName] && 
        errors[fieldName][fieldIndex] && 
        errors[fieldName][fieldIndex][fieldSubName] && 
        touched[fieldName] && 
        touched[fieldName][fieldIndex] && 
        touched[fieldName][fieldIndex][fieldSubName]  ? errorClass || "has-error" : ""
      }`}
      placeholder={ placeholder || "Smoker"}
      component="select"
      name={name || "smoker"}
      validate={validate}
      onChange={e => {
        handleChange(e)
        setField(e)
      }}
    >
      <option data-testid="smoker-none" value="">Select</option>
      <option data-testid="smoker-val-0" value="0">No</option>
      <option data-testid="smoker-val-1" value="1">Yes</option>
    </Field>
    {
      noErrorMessage ?
      null :
      <ErrorMessage name={name || "smoker"} component="div" className="field-error" data-testid="smoker-error" />
    }
  </div>
)

export default tobaccoUse
